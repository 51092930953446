/* eslint-disable */
import React, { useState } from 'react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { Link } from 'gatsby'
import { Box, Container, Grid, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({}))

const Test = () => {
	const classes = useStyles()
	const [isLogoActive, setLogoActive] = useState(true)

	const onScroll = e => {
		if (e.target.scrollTop < 100) {
			console.log('triggered')
		}

		// setLogoActive(e.target.scrollTop < 100)
	}

	return (
		<>
			<Typography variant='h4' gutterBottom>
				Test Page
			</Typography>
			<div className='content-container'>
				<Typography variant='body1' color='textPrimary' gutterBottom>
					Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repudiandae
					doloribus delectus eligendi ipsa voluptates impedit ipsam enim ut
					explicabo, recusandae nam iusto atque eum nobis fugit dicta commodi,
					tempora fugiat?
				</Typography>
				<div onScroll={onScroll} style={{ height: 300, overflowY: 'scroll' }}>
					<p style={{ marginBottom: 200 }}>top</p>
					<img
						style={{
							width: 100,
							height: 100,
							visibility: isLogoActive ? 'visible' : 'hidden'
						}}
						src='https://arcweb.co/wp-content/uploads/2016/10/react-logo-1000-transparent-768x768.png'
					/>
					<p style={{ marginTop: 200 }}>bottom</p>
				</div>
			</div>
		</>
	)
}

export default Test
